import { PlatformControllerFlowAPI, type ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { toError, isWixHttpError } from './errors';

type Predicate = (e: unknown, flowAPI: ControllerFlowAPI | PlatformControllerFlowAPI) => boolean;

const IGNORED_ERROR_PREDICATES: Predicate[] = [
  (e) => isWixHttpError(e) && e.message === 'Network Error',
  (e, flowAPI) => flowAPI.environment.isEditor && isWixHttpError(e) && e.response?.status === 403,
];

type Params = Parameters<ControllerFlowAPI['errorMonitor']['captureException']>;

export function captureViewerException(
  flowAPI: ControllerFlowAPI | PlatformControllerFlowAPI,
  error: unknown,
  options?: Params[1],
) {
  const isErrorIgnored = IGNORED_ERROR_PREDICATES.some((isIgnored) => isIgnored(error, flowAPI));
  if (!isErrorIgnored) {
    flowAPI.errorMonitor.captureException(toError(error), options);
  }
}
